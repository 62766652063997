.MuiMenu-paper {
  max-height: 300px !important;
  margin-top: 5px !important;
}

.paypal-button-tagline {
  color: '#ffffff' !important;
}

.rccs,
.rccs__card {
  margin: 0 !important;
}

.rccs {
  width: 400px !important;
}

.rccs__card {
  width: 400px !important;
  height: 230px !important;
}

@media (max-width: 1240px) {
  .queryButton {
    height: 43px;
    font-size: 13px;
    padding: 8px 22px;
  }
  .queryContactBox {
    margin-top: 50px;
  }
}

@media (max-width: 700px) {
  .queryButton {
    height: 43px;
    font-size: 12px;
  }
  .queryContactBox {
    position: absolute;
    top: 300px;
  }
  th {
    font-size: 14px !important;
  }
  .unsubscribe-button {
    font-size: 13px !important;
  }
}

@media (max-width: 700px) {
  .queryInput {
    width: 100%;
    max-width: unset;
  }
}

@media (max-width: 450px) {
  .queryContactBox {
    top: 430px;
  }
}

.unsubscribe-button {
  background-color: #009e4f !important;
}
